import firebase from "firebase";

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyBp4jTyFPwY3J6eBbVzXJhRR7wFfT5rwN0",
  authDomain: "apps-9abda.firebaseapp.com",
  databaseURL: "https://apps-9abda.firebaseio.com",
  projectId: "apps-9abda",
  storageBucket: "apps-9abda.appspot.com",
  messagingSenderId: "884430526141",
  appId: "1:884430526141:web:5051c6efd7b773502949d4"
});

const db = firebaseApp.firestore();

export { db };