
import React, { useEffect, useState } from 'react'
import { db } from './infraestructure/firebase';
import './App.css'
import { data } from './infraestructure/firebase/data'

function App() {

  const [reducer, setreducer] = useState({
    loading: true,
    error: false
  })

  useEffect(() => {
    // updateData()
    redirectUrl()
  }, [])


  const redirectUrl = async () => {
    // console.log(window.location = 'https://google.com')
    try {
       db.collection("coty").where('used', '!=', true).limit(1)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(doc => {
            db.collection("coty").doc(doc.data().code).update({
              used: true
            })
            window.location.href = doc.data().url
          })
        })
        .catch(function (error) {
            setreducer({
              loading: false,
              error: true
            })
        });
    } catch (error) {
      setreducer({
        loading: false,
        error: true
      })
    }

  }

  const updateData = async () => {
    data.map((i, index) => {
      db.collection("coty").doc(i.code).set({
        ...i,
        used: false
      })
        .then(function () {
          console.log("Document successfully written!");
        })
        .catch(function (error) {
          console.error("Error writing document: ", error);
        });

    })
  }


  if (reducer.loading) {
    return <div className="App">
      <p class="title">Connecting to the iTunes Store.<span id="loading-dots"></span></p>
    </div>
  }
  return (
    <div className="App">
      <p>Opps. Ha ocurrido un error.</p>
      <button onClick={redirectUrl}>REINTENTAR</button>
    </div>
  );
}

export default App;
